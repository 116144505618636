import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { RouteGuardService } from './helpers/route-guard.service'
const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'imposta-password/:username/:token',
    loadChildren: () => import('./pages/authentication/set-new-customer-password/set-new-customer-password.module').then(m => m.SetNewCustomerPasswordModule),
  },
  {
    path: 'conferma-registrazione/:username/:token',
    loadChildren: () => import('./pages/authentication/attiva-utente/attiva-utente.module').then(m => m.AttivaUtenteModule),
  },
  /*{
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },*/
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'registration-requests/:tipoId',
        loadChildren: () => import('./pages/registration-requests/list/registration-requests.module').then(m => m.RegistrationRequestsModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'registration-requests-closed/:tipoId',
        loadChildren: () => import('./pages/registration-requests/list/registration-requests.module').then(m => m.RegistrationRequestsModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'customer/list',
        loadChildren: () => import('./pages/customer/customers-list.module').then(m => m.CustomersListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'area',
        loadChildren: () => import('./pages/aree/aree-list.module').then(m => m.AreeListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'richieste',
        loadChildren: () => import('./pages/address-update-request/address-update-request-list.module').then(m => m.AddressUpdateRequestListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FAQModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'gestione-comuni',
        loadChildren: () => import('./pages/comuni/comuni-list.module').then(m => m.ComuniListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'product/list',
        loadChildren: () => import('./pages/product/products-list.module').then(m => m.ProductsListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'product/categorie',
        loadChildren: () => import('./pages/product/categorie/categorie.module').then(m => m.CategorieModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'shipping/calendar',
        loadChildren: () => import('./pages/shipping/calendar/shipping-calendar.module').then(m => m.ShippingCalendarAppModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'shipping/list',
        loadChildren: () => import('./pages/shipping/list/shipping-list.module').then(m => m.ShippingListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'orders/list',
        loadChildren: () => import('./pages/orders/list/orders-list.module').then(m => m.OrdersListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'orders/statistics',
        loadChildren: () => import('./pages/orders/statistics/orders-stats.module').then(m => m.OrdersStatsModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'statistiche',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate:[RouteGuardService] 
      },
      /*{
        path: 'programma-fedelta/configura-fasce',
        loadChildren: () => import('./pages/programma-fedelta/configurazione-fasce/fasce-punti-list.module').then(m => m.FascePuntiListModule),
        canActivate:[RouteGuardService] 
      },*/
      {
        path: 'programma-fedelta/configura-prodotti',
        loadChildren: () => import('./pages/programma-fedelta/configurazione-prodotti/prodotti-regalo-list.module').then(m => m.ProductsGiftListModule),
        canActivate:[RouteGuardService] 
      },
      {
        path: 'components',
        loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule),
      },
      {
        path: 'configurazione',
        loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationtModule),
      },
      {
        path: 'configurazione-registrazioni',
        loadChildren: () => import('./pages/configurazione-registrazioni/configurazione-registrazioni.module').then(m => m.ConfigurazioneRegistrazioniModule),
      },
      {
        path: 'drag-and-drop',
        loadChildren: () => import('./pages/drag-and-drop/drag-and-drop.module').then(m => m.DragAndDropModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
