<mat-tab-group class="quickpanel" fxFlex>
  <mat-tab label="Overview">
    <fury-scrollbar class="overflow-container">
      <div class="text-padding">
        <p>Oggi</p>
        <p class="h1">
          <span>{{ todayDay | titlecase}}</span> <br/>
          <span>{{ todayDate }}</span><span> {{ todayMonth | titlecase}}</span>
        </p>
      </div>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <h3 matSubheader>Consegne odierne (1)</h3>
        <a href="javascript:" mat-list-item>
          <h4 matLine>Consegna Mattino</h4>
          <p matLine>Area: Area 1</p>
          <p matLine>Autista: Mario Rossi</p>
        </a>
        <mat-divider></mat-divider>
        <h3 matSubheader>Prossime Consegne</h3>
        <a mat-list-item>
          <h4 matLine>Consegna Mattino</h4>
          <p matLine>8/11/2020</p>
          <p matLine>Area: Area 1</p>
          <p matLine>Autista: Mario Rossi</p>
        </a>
        <a mat-list-item>
          <h4 matLine>Consegna Pomeriggio</h4>
          <p matLine>8/11/2020</p>
          <p matLine>Area: Area 3</p>
          <p matLine>Autista: Mario Rossi</p>
        </a>
        <!--<mat-divider></mat-divider>
        <h3 matSubheader>SERVER STATISTICS</h3>
        <a mat-list-item>
          <p matLine>CPU Load (71% / 100%)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="primary" value="71"></mat-progress-bar>
          </p>
        </a>
        <a mat-list-item>
          <p matLine>RAM Usage (6,175 MB / 16,384 MB)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="accent" value="34"></mat-progress-bar>
          </p>
        </a>
        <a mat-list-item>
          <p matLine>CPU Temp (43° / 80°)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="warn" value="54"></mat-progress-bar>
          </p>
        </a>
        -->
      </mat-nav-list>
    </fury-scrollbar>
  </mat-tab>
  <mat-tab label="Notifiche">
    <fury-scrollbar class="overflow-container">
      <mat-nav-list>
        <h3 matSubheader>FRIENDS</h3>
        <a href="javascript:" mat-list-item>
          <img mat-list-avatar src="">
          <h3 matLine> Sophie </h3>
          <p matLine>
            <span> Dinner? </span>
            <span class=""> -- Are we still going out tonight? </span>
          </p>
        </a>
        <a href="javascript:" mat-list-item>
          <img mat-list-avatar src="">
          <h3 matLine> Jack </h3>
          <p matLine>
            <span> Golf weekend </span>
            <span class=""> -- Hey! You wanted to go play Golf? </span>
          </p>
        </a>
        <a href="javascript:" mat-list-item>
          <img mat-list-avatar src="">
          <h3 matLine> Cody </h3>
          <p matLine>
            <span> Code Quality </span>
            <span class=""> -- Love your newest theme, so clean and slick! </span>
          </p>
        </a>
        <a href="javascript:" mat-list-item>
          <img mat-list-avatar src="">
          <h3 matLine> James </h3>
          <p matLine>
            <span> Gaming? </span>
            <span class=""> -- You wanna throw a party this weekend? </span>
          </p>
        </a>
        <a href="javascript:" mat-list-item>
          <img mat-list-avatar src="">
          <h3 matLine> Jessica </h3>
          <p matLine>
            <span> Love you... </span>
            <span class=""> -- Hope we can see us again soon :) </span>
          </p>
        </a>

        <mat-divider></mat-divider>

        <h3 matSubheader>SERVER STATISTICS</h3>
        <a mat-list-item>
          <p matLine>CPU Load (71% / 100%)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="primary" value="71"></mat-progress-bar>
          </p>
        </a>
        <a mat-list-item>
          <p matLine>RAM Usage (6,175 MB / 16,384 MB)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="accent" value="34"></mat-progress-bar>
          </p>
        </a>
        <a mat-list-item>
          <p matLine>CPU Temp (43° / 80°)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="warn" value="54"></mat-progress-bar>
          </p>
        </a>
      </mat-nav-list>
    </fury-scrollbar>
  </mat-tab>
</mat-tab-group>
