import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
    
    private data = new Subject;
    currentData = this.data.asObservable()
    
    constructor() { }

    updateMessage() {
        this.data.next();
    }

}