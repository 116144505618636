<div class="title">{{ current }}</div>
<div class="crumbs" fxLayout="row" fxLayoutAlign="start center">
  <a [routerLink]="['/']" class="crumb home">Home</a>
  <div *ngFor="let crumb of crumbs" class="crumb" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ crumb }}</div>
  </div>
  <div class="crumb current" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ current }}</div>
  </div>
</div>
