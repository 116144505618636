import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Immagini } from '../pages/product/prodotti.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient, private route: Router) { }
  private baseUrl = environment.baseUrl;
  private productEndpoint = environment.productEndpoint;
  private categoryEndpoint = environment.categoryEndpoint;
  private subCategoryEndpoint = environment.subCategoryEndpoint;
  private unitaMisuraEndpoint = environment.unitaMisuraEndpoint;
  private imagesEndPoint = environment.imagesEndPoint;
  
  getProducts(){
    return this.http.post<any>(this.baseUrl + this.productEndpoint, {});
  }
  createProduct(entity){
    return this.http.post<any>(this.baseUrl + this.productEndpoint + "/inserisci", entity, {observe: 'response'});
  }
  updateProduct(entity){
    return this.http.put<any>(this.baseUrl + this.productEndpoint + "/aggiorna", entity, {observe: 'response'});
  }
  deleteProduct(id){
    return this.http.get<any>(this.baseUrl + this.productEndpoint + "/elimina/"+id, {observe: 'response'});
  }

  getCategories(){
    return this.http.post<any>(this.baseUrl + this.categoryEndpoint, {});
  }
  createCategory(entity){
    return this.http.post<any>(this.baseUrl + this.categoryEndpoint + "/inserisci", entity, {observe: 'response'});
  }
  updateCategory(entity){
    return this.http.put<any>(this.baseUrl + this.categoryEndpoint + "/aggiorna", entity, {observe: 'response'});
  }
  deleteCategory(id){
    return this.http.get<any>(this.baseUrl + this.categoryEndpoint+ "/elimina/"+id, {observe: 'response'});
  }

  getSubCategories(){
    return this.http.post<any>(this.baseUrl + this.subCategoryEndpoint, {});
  }
  getUM(){
    return this.http.post<any>(this.baseUrl + this.unitaMisuraEndpoint, {});
  }

  getImages(id){
    return this.http.post<Immagini[]>(this.baseUrl + this.imagesEndPoint+"/list/"+id,{});
  }
  uploadImage(formData){
    return this.http.post(this.baseUrl + this.imagesEndPoint+"/upload", formData);
  }
  deleteImage(id){
    return this.http.post<Immagini[]>(this.baseUrl + this.imagesEndPoint+"/delete/"+id,{});
  }
  setImageAsDefault(id_prod,id_immagine){
    return this.http.post(this.baseUrl + this.imagesEndPoint+"/setdefault/"+id_prod+"/"+id_immagine, {});
  }
}

