import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload.component'
import { DndDirective } from './dnd.directive';


@NgModule({
  imports:      [ CommonModule],
  declarations: [ FileUploadComponent, DndDirective ],
  bootstrap:    [ FileUploadComponent ],
  exports: [FileUploadComponent]
})
export class FileUploadModule { }
