import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/UserModel';
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  isOpen: boolean;
  user: UserModel;

  constructor(private route: Router, private auth: AuthService) { }

  ngOnInit() {
    this.user = this.auth.getUser();
  }

  logout(){
    localStorage.clear();
    this.route.navigate(['/login']);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

}
