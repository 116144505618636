import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AvatarModule } from 'ngx-avatar';
import { HttpInterceptorService } from './helpers/http-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from './shared/file-upload-dropzone/file-upload.module';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {LocationStrategy, HashLocationStrategy, DatePipe} from '@angular/common';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FileUploadModule,
    AppRoutingModule,
    LayoutModule,
    AvatarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      progressBar:true
    }),
    PendingInterceptorModule,
    DxDataGridModule,
    DxTemplateModule,
    MatFormFieldModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top'
      } as MatSnackBarConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: 'it-IT' },
    {provide: MAT_DATE_LOCALE, useValue: {useUtc:true} },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc:true} }
  ]
})
export class AppModule {
}
platformBrowserDynamic().bootstrapModule(AppModule);
