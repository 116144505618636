// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  googleMapsApiKey: '',
  //baseUrl: 'http://localhost:5001/api/',
  baseUrl: 'https://api.maivo.it/api/',
  authAPI: 'utenti/auth',
  dashboardEndPoint: 'dashboard',
  productEndpoint: 'prodotti',
  categoryEndpoint: 'categorie',
  subCategoryEndpoint:'sottocategorie',
  unitaMisuraEndpoint:'unitamisura',
  consegneEndpoint: 'consegne',
  clientiEndpoint: 'clienti',
  assegnaAreaClienteEndpoint: 'area/inserisci',
  rimuoviAreaClienteEndpoint: 'area/delete',
  statiConsegneEndpoint: 'staticonsegna',
  areeEndpoint: 'aree',
  comuniEndpoint: 'comuni',
  provinceEndpoint: 'province',
  regioniEndpoint: 'regioni',
  imagesEndPoint: 'prodotti/immagini',
  ordiniEndPoint: "ordini",
  righeOrdineEndPoint: "/righe",
  statiOrdiniEndPoint: "stati",
  statisticheEndpoint: "statistiche/piuvenduti",
  richiesteEndpoint: "clienti/indirizzi/richiestemodifica",
  fascePuntiEndpoint: "fascePunti",
  productGiftEndpoint: "prodottiRegalo",
  faqEndpoint: "FAQ",
  imagesProductGiftEndPoint: "prodottiRegalo/immagini/upload",
  imagesProductGiftPath:"https://api.maivo.it/ImmaginiProdottiRegalo/",
  richiesteRegistrazioneEndPoint: "richiesteRegistrazione"
};
