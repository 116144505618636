import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class RichiesteService {

  constructor(private http: HttpClient, private route: Router) { }
  private baseUrl = environment.baseUrl;
  private richiesteEndpoint = environment.richiesteEndpoint;

  get(){
    return this.http.post<any>(this.baseUrl + this.richiesteEndpoint,{});
  }
  getCount(){
    return this.http.post<any>(this.baseUrl + this.richiesteEndpoint+"/count",{});
  }
  update(entity){
    return this.http.put<any>(this.baseUrl + this.richiesteEndpoint + "/aggiorna", entity, {observe: 'response'});
  }
  create(entity){
    return this.http.post<any>(this.baseUrl + this.richiesteEndpoint + "/inserisci", entity, {observe: 'response'});
  }
  delete(id){
    return this.http.get<any>(this.baseUrl + this.richiesteEndpoint + "/elimina/"+id, {observe: 'response'});
  }

}
