<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
      <ngx-avatar name="{{user.nome}} {{user.cognome}}" size="35" [round]="true"></ngx-avatar>
      <span class="name" fxHide fxShow.gt-sm>{{user.nome}} {{user.cognome}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">account_circle</mat-icon>
          <span>Profilo</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple (click)="logout()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>Logout</span>
        </div>
      </div>
    </div>
  </div>
</div>
