import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserModel } from '../models/UserModel'
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private route: Router) { }
  private baseUrl = environment.baseUrl;
  private authAPI = environment.authAPI;
  
  login(username, password){
    return this.http.post<any>(this.baseUrl + this.authAPI, {"Email": username, "Password": password});
  }

  setUser(loginData){
    localStorage.setItem('user', JSON.stringify(loginData));
  }

  getUser(){
    if(this.isAuth()){
      let userStr = localStorage.getItem('user');
      let jsonObj: any = JSON.parse(userStr); 
      let user: UserModel = <UserModel>jsonObj;
      return user;
    } else{
      this.clearUserData();
      this.route.navigate(['/login']);
    }
  }

  clearUserData(){
    localStorage.removeItem("user");
  } 

  isAuth(){
    let userStr = localStorage.getItem('user');
    if(userStr ==undefined || userStr==null || userStr.length==0)
      return false;
    
    let jsonObj: any = JSON.parse(userStr); 
    let user: UserModel = <UserModel>jsonObj;
    var token = user.token;
    var decodedToken = jwt_decode(token);

    var expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);

    if(expirationDate < new Date()) {
      this.clearUserData();
      return false;
    }
    
    return true;
  }
}
